import React, { Component } from 'react';

// Images
import ImgEcosystem from '../../assets/img/home/img-ecosystem-new.png'
import ImgEcosystemMobile from '../../assets/img/home/img-ecosystem-mobile.png'


class Section5 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
        <section class="new-home-44 text-center">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h2 class="poppins font-44 bold white-text text-center text-uppercase double-underline double-underline-3">
                            earn on the entire ecosystem
                        </h2>
                        
                        <p class="poppins font-16 regular white-text text-center">
                            Once user is in the ecosystem you earn on all activities forever 
                        </p>
                        
                        <div class="img-wrap text-center">
                            <img class="desktop" src={ImgEcosystem} alt="Ecosystem" />
                            <img class="mobile mx-auto" width="300" src={ImgEcosystemMobile} alt="Ecosystem" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
  }
}

export default Section5;