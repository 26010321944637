import React, { Component } from 'react';

// Components
import DownloadAppWrap from '../DownloadAppWrap'

// CSS
import '../../assets/css/Footer.css'

// Images
import OvatoLogo from '../../assets/img/footer/ovato-logo.png'

class FooterForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
        <>
            <div className='img-wrap footer-img-ovato'>
                <img width={279} src={OvatoLogo} />
            </div>
            
            <p className='font-1 text-center v2-font-18 v2-tl-font-16 semi-bold white-text text-uppercase lh-1-3'>Join Our Newsletter</p>

            <form className='footer-form'>
                <div>
                    <input type='email' name='email' placeholder='Email Address' />
                </div>

                <div>
                    <button>Subscibe Now</button>
                </div>
            </form>

            <DownloadAppWrap />
        </>
    );
  }
}

export default FooterForm;