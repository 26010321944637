import React, { Component } from 'react';

// Images
import GooglePlay from '../assets/img/footer/img-google-play.png'
import AppleStore from '../assets/img/footer/img-apple-store.png'

class DownloadAppWrap extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
        <>
            <div className='d-flex align-items-center v2-mt-1-5 justify-content-center dl-app-wrap'>
                <div className='v2-mx-0-25 img-wrap'>
                    <a href='#'><img src={GooglePlay} /></a>
                </div>

                <div className='v2-mx-0-25 img-wrap'>
                    <a href='#'><img src={AppleStore} /></a>
                </div>
            </div>
        </>
    );
  }
}

export default DownloadAppWrap;