import React, { Component } from 'react';

// Hooks
import ModalVideoManager from '../modal/ModalVideoManager';

// Images
import ImgVideoPlay from '../../assets/img/home/img-video-play-poster.jpg'
import ImgBulletProof from '../../assets/img/home/img-bulletproof-security.png'
import ImgFastEasy from '../../assets/img/home/img-fast-and-easy.png'
import ImgBank from '../../assets/img/home/img-bank.png'

class Section4 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
        <section class="new-home-4 new-home-4-new">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <h2 class="poppins text-center v2-font-36 v2-m-font-24 bold dark-text-2 ">
                  <strong class="double-underline double-underline-heading">You're the Proof!</strong>
                </h2>
              </div>
            </div>
            
            <div class="row align-center new-home-4-row-2 new-home-4-row-2-new">
              <div class="col-sm-7">
                <ModalVideoManager videoId="mF_whPP6Nuc" render={({ handleOpen }) => (
                    <>
                      <div class="img-wrap new-home-4-img-left new-home-4-img-left-new">
                        <a onClick={handleOpen}><img className='video-wrap' src={ImgVideoPlay} alt="Play Video" /></a>
                      </div>
                    </>
                  )}/>
                
              </div>
              
              <div class="col-sm-5">
                <h3 class="poppins v2-font-24 v2-m-font-18 semi-bold dark-text-3">
                  If you're here - you just proved it!
                </h3>
                
                <ul class="v2-mt-2 ul-checklist gray-text-2">
                  <li>You already have a wallet!</li>
                  <li>The person who referred you has already earned on you!</li>
                  <li>This person will continue to make overrides every time you use Ovato forever!</li>
                </ul>
              </div>
            </div>
            
            <div class="row">
              <div class="col">
                <div class="new-home-4-img-text-wrap new-home-4-img-text-wrap-new">
                  <div class="img-wrap">
                    <img src={ImgBulletProof} alt="Bulletproof Security Icon" />
                  </div>

                  <div class="text-wrap">
                    <h3>
                      Bulletproof Security
                    </h3>

                    <p>
                      All transactions done over the <br /> Blockchain and settled in 10 seconds
                    </p>
                  </div>
                </div>
              </div>
              
              <div class="col">
                <div class="new-home-4-img-text-wrap new-home-4-img-text-wrap-new">
                  <div class="img-wrap">
                    <img src={ImgFastEasy} alt="Fast and Easy Icon" />
                  </div>

                  <div class="text-wrap">
                    <h3>
                      Fast and Easy Interface
                    </h3>

                    <p>
                      End to end solution that allows users to <br /> do transactions in about a second
                    </p>
                  </div>
                </div>
              </div>
              
              <div class="col">
                <div class="new-home-4-img-text-wrap new-home-4-img-text-wrap-new">
                  <div class="img-wrap">
                    <img src={ImgBank} alt="Fast and Easy Icon" />
                  </div>

                  <div class="text-wrap">
                    <h3>
                      You are your own bank 
                    </h3>

                    <p>
                      Your money is your own -no custodial <br /> agents whatsoever. You are your own bank
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
    );
  }
}

export default Section4;