import React, { Component } from 'react';

import { Link, useNavigate } from 'react-router-dom';

// Providers
import { User } from '../../providers/User';

// Components
import NavigationLink from '../NavigationLink'
import LoginModal from '../modal/LoginModal';
import TopMenu from '../menu/TopMenu';
import TopMenuSteps from '../menu/TopMenuSteps';

// CSS
import '../../assets/css/Header2.css'

// Images
import Logo from '../../assets/img/header/logo.png'

import FlagUsa from '../../assets/img/header/flag-usa.jpg'
import FlagChina from '../../assets/img/header/flag-china.jpg'
import FlagGermany from '../../assets/img/header/flag-germany.jpg'
import FlagJapan from '../../assets/img/header/flag-japan.jpg'
import FlagKorean from '../../assets/img/header/flag-korean.jpg'
import FlagSpain from '../../assets/img/header/flag-spain.jpg'

import imgMobileBtn from '../../assets/img/header/icon-mobile-menu-blue.png'

class Header2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
        showTranslation: false,
        isSticky: false,
        showModal: false
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY > 100) {
      this.setState({ isSticky: true });
    } else {
      this.setState({ isSticky: false });
    }
  }

  scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: Scroll behavior smooth for smooth scrolling
    });
  };

  toggleTranslation = () => {
    this.setState((prevState) => ({
      showTranslation: !prevState.showTranslation
    }));
  };

  handleNavigation = (e, url) => {
        e.preventDefault();
        // if (!User.hasToken()) {
        //     this.setState({ showModal: true })
        // } else {
        //     window.location.href = url;
        // }
        window.location.href = url;
    }

    handleModalClose = () => {
        this.setState({ showModal: false })
    }

    handleLogout = () => {
        localStorage.clear()
        window.location.href = '/'
    }

  render() {

    return (
        <>
        <header class={`site-header new-site-header ${this.state.isSticky ? 'active' : ''}`}>
            <div class='site-header-upper-new'>
                <div class='container'>
                    <p class='poppins p-1 font-22 ls-3 bold white-text text-center text-uppercase m-0'>
                        Easiest Way To <strong class='orange-text'>Make Big Money</strong> in Crypto !
                    </p>
                </div>
            </div>
            
            <div class='site-header-lower'>
                <div class="container">
                    <div class="row">
                        <div class="col-sm site-logo-col">
                            <div class="site-logo">
                                <Link to="/">
                                    <img src={Logo} alt="Govato" />
                                </Link>
                            </div>
                            {/* <!-- end class site-logo --> */}
                        </div>
                        {/* <!-- end class col --> */}
                        <div class="col-sm site-menu-col">

                            {/* <ul class="site-menu site-menu-2">
                                <li><a href="/how-it-works">How it Works</a></li>
                                <li><a href="/compensation-plan" onClick={(e) => this.handleNavigation(e, "compensation-plan") }>Compensation Plan</a></li>
                                <li><a href="/nfts" onClick={(e) => this.handleNavigation(e, "nfts") }>NFTs</a></li>
                                <li><a href="/tools" onClick={(e) => this.handleNavigation(e, "tools") }>Tools</a></li>
                                <li><a href="/rules" onClick={(e) => this.handleNavigation(e, "rules") }>Rules</a></li>
                                <li><a href="/get-started" onClick={(e) => this.handleNavigation(e, "get-started") }>Get Started</a></li>
                                { User.hasToken() && <li><a href="#" onClick={() => this.handleLogout() }>Logout</a></li>      }
                            </ul> */}

                            {/* <TopMenu 
                                handleNavigation={this.handleNavigation} 
                                handleLogout={this.handleLogout}
                            /> */}

                            <TopMenuSteps 
                                handleNavigation={this.handleNavigation} 
                                handleLogout={this.handleLogout}
                            />

                            {/* <!-- end class site-menu --> */}
                        </div>
                        {/* <!-- end class col --> */}
                        <div class="col-sm site-translation-col">
                            <div class='site-translation-wrap'>
                                <div class='site-translation-selected' onClick={this.toggleTranslation}>
                                    <img class='site-translation-selected-flag' src={FlagUsa} alt='USA flag' />
                                    <span class='site-translation-selected-lang'>EN</span>
                                    <span class='site-translation-selected-down'><i class="fas fa-chevron-down"></i></span>
                                </div>
                                <ul class={`site-translation-options ${this.state.showTranslation && 'active'}`}>
                                    <li class='site-translation-option-heading'>Select Language</li>
                                    <li class='site-translation-option-text' data-val='en'>
                                        <img src={FlagUsa} alt='USA flag' />
                                        <span>English</span>
                                    </li>
                                    <li class='site-translation-option-text' data-val='zh-CN'>
                                        <img src={FlagChina} alt='China flag' />
                                        <span>Chinese</span>
                                    </li>
                                    <li class='site-translation-option-text' data-val='de'>
                                        <img src={FlagGermany} alt='Germany flag' />
                                        <span>German</span>
                                    </li>
                                    <li class='site-translation-option-text' data-val='ko'>
                                        <img src={FlagKorean} alt='Korea flag' />
                                        <span>Korean</span>
                                    </li>
                                    <li class='site-translation-option-text' data-val='ja'>
                                        <img src={FlagJapan} alt='Japan flag' />
                                        <span>Japanese</span>
                                    </li>
                                    <li class='site-translation-option-text' data-val='es'>
                                        <img src={FlagSpain} alt='Spain flag' />
                                        <span>Spanish</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- end class col --> */}

                        <div class="site-menu-mobile-btn">
                            <a href="#" data-bs-toggle="offcanvas" data-bs-target="#mobile-menu" aria-controls="mobile-menu">
                                <img src={imgMobileBtn} />
                            </a>
                        </div>
                    </div>
                    {/* <!-- end class row --> */}
                </div>
                {/* <!-- end class container --> */}
            </div>
            {/* <!-- end class site-header-lower --> */}
        </header>
        {/* // <!-- end class header --> */}

        {/* <!-- Mobile Menu --> */}
        {!this.state.showModal && (
            <>
                <div class="offcanvas offcanvas-start mobile-menu" tabindex="-1" id="mobile-menu" aria-labelledby="mobile-menu">
                    <div class="offcanvas-body">
                        <TopMenuSteps 
                            handleNavigation={this.handleNavigation} 
                            handleLogout={this.handleLogout}
                            isMobile={true}
                        />
                        {/* <ul class="mobile-site-menu">
                            <li><a href="/how-it-works">How it Works</a></li>
                            <li><a href="/compensation-plan" onClick={(e) => this.handleNavigation(e, "compensation-plan") }>Compensation Plan</a></li>
                            <li><a href="/nfts" onClick={(e) => this.handleNavigation(e, "nfts") }>NFTs</a></li>
                            <li><a href="/tools" onClick={(e) => this.handleNavigation(e, "tools") }>Tools</a></li>
                            <li><a href="/rules" onClick={(e) => this.handleNavigation(e, "rules") }>Rules</a></li>
                            <li><a href="/get-started" onClick={(e) => this.handleNavigation(e, "get-started") }>Get Started</a></li>
                            { User.hasToken() && <li><a href="#" onClick={() => this.handleLogout() }>Logout</a></li>      }
                        </ul> */}
                        <a class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></a>
                        <ul class="site-social">
                            <li><a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCVxylQ08a9l54G2uRqZTdHA"><i class="fab fa-youtube"></i></a></li>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/ovato"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/OvatoCoin"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://twitter.com/OvatoCoin"><i class="fab fa-twitter"></i></a></li>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/ovatocoin"><i class="fab fa-instagram"></i></a></li>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://t.me/Ovato_Coin"><i class="fab fa-telegram"></i></a></li>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://bitcointalk.org/index.php?topic=3012780.msg30971176#msg30971176"><i class="fab fa-bitcoin"></i></a></li>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://www.reddit.com/user/ovatotoken/"><i class="fab fa-reddit"></i></a></li>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://github.com/Ovato-Pte-Ltd"><i class="fab fa-github"></i></a></li>
                        </ul>
                        {/* <!-- end class site-social --> */}
                    </div>
                    {/* <!-- end class offcanvas-body --> */}
                </div>
                {/* <!-- end class offcanvas --> */}
                </>
            )
        }

        {/* <!-- Social Mobile Menu --> */}
        <div class="offcanvas offcanvas-start mobile-menu" tabindex="-1" id="social-mobile-menu" aria-labelledby="social-mobile-menu">
            <div class="offcanvas-body">
                <ul class="site-social">
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCVxylQ08a9l54G2uRqZTdHA"><i class="fab fa-youtube"></i></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/OvatoCoin"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/OvatoCoin"><i class="fab fa-twitter"></i></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/ovato"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://t.me/Ovato_Coin"><i class="fab fa-telegram"></i></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/ovatocoin"><i class="fab fa-instagram"></i></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://bitcointalk.org/index.php?topic=3012780.msg30971176#msg30971176"><i class="fab fa-bitcoin"></i></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/Ovato-Pte-Ltd"><i class="fab fa-github"></i></a></li>
                </ul>
                <a class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></a>
            </div>
            {/* <!-- end class offcanvas-body --> */}
        </div>
        {/* <!-- end class offcanvas --> */}
        
        {/* Scroll To Top */}
        <div class={`scroll-to-top ${this.state.isSticky ? 'active' : ''}`}>
            <a href="#" onClick={this.scrollToTop}><i class="fas fa-chevron-up"></i></a>
        </div>
        
        <LoginModal showModal={this.state.showModal} handleModalClose={this.handleModalClose} />
        </>
    );
  }
}

export default Header2;