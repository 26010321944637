import { Link, useLocation } from 'react-router-dom';

function SignUpButton({}) {

  return (
    <div class="text-center btn-wrap mt-40">
        <a href="#" class="site-btn site-btn-2 site-btn-subtext signup-v3-btn">
            Sign Up Now
        </a>
    </div>
  );
}

export default SignUpButton;