import React, { Component } from 'react';

import { Row, Col, Input, Label, Button, Container } from 'reactstrap';

// Hooks
import ModalVideoManager from '../components/modal/ModalVideoManager';

// CSS
import '../assets/css/CompensationPlan2.css'

// Components
import Header from '../components/header/Header2';
import Footer from '../components/footer/Footer2';
import SignUpButton from '../components/SignUpButton';

// Images
import ImgVideo from '../assets/img/compensation-plan/img-video-compensation-2.jpg'
import ImgVideoPlay from '../assets/img/compensation-plan/icon-video-play.png'
import ImgCircle from '../assets/img/compensation-plan-2/img-circle.png'
import ImgCircleMobile from '../assets/img/compensation-plan-2/img-circle-mobile.png'
import ImgRocket from '../assets/img/compensation-plan-2/img-rocket.png'
import ImgPlayVideoText from '../assets/img/compensation-plan-2/img-play-video.png'

class CompensationPlan2 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className='v2-comp-plan-main'>
            <Header />

            <section className='first-row v2-m-pt-2 v2-m-pb-2 v2-comp-plan-1'>
                <Container className='relative z-index-2'>
                    <Row>
                        <Col>
                            <h1 className='v2-font-30 v2-m-font-28 poppins bold dark-text-2'>Multiple Ways <br /> To Earn Money</h1>

                            <ModalVideoManager videoId="H_pc3Q2R1HQ" render={({ handleOpen }) => (
                                <>
                                    <div class="video-wrap c-video-wrap v2-c-video-wrap">
                                        <div class="c-video-img-wrap">
                                            <img onClick={handleOpen} src={ImgVideo} />
                                        </div>
                                        
                                        <div class="c-video-play-btn">
                                            <a onClick={handleOpen}><img src={ImgVideoPlay} /></a>
                                        </div>
                                        
                                        <div class="c-video-play-text">
                                            <img class="desktop" src={ImgPlayVideoText} />
                                            <img class="mobile" src={ImgPlayVideoText} />
                                        </div>
                                    </div>
                                </>
                            )}/>

                            

                            <div class="btn-wrap v2-mt-4 v2-m-mt-3">
                                <a href="/nfts" class="site-btn site-btn-2 site-btn-subtext signup-v3-btn v2-c-btn">
                                    NFTs
                                </a>
                            </div>

                        </Col>

                        <Col className='desktop'>
                            <div className='img-wrap v2-tp-mw-70 v2-tp-mt-4 v2-m-mw-100'><img src={ImgCircle} /></div>

                            <div className='v2-mt--10 v2-m-mt--2 text-center img-wrap'><img className='v2-tp-mw-40 v2-m-mw-30' width={180} src={ImgRocket} /></div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='v2-comp-plan-2'>
                <Container className='relative z-index-2 mobile'>
                    <Row>
                        <Col>
                            <div className='img-wrap v2-tp-mw-70 v2-tp-mt-4 v2-m-mw-100'><img src={ImgCircleMobile} /></div>

                            <div className='v2-mt--10 v2-m-mt--2 text-center img-wrap'><img className='v2-tp-mw-40 v2-m-mw-30' width={180} src={ImgRocket} /></div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />
        </div>
    );
  }
}

export default CompensationPlan2;