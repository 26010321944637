import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

// Crypto
import Crypto from 'crypto-js'

// Default Values
import { secret, toastStyle } from "../../constants/defaultValues"

// Providers
import { UserAuth } from '../../providers/UserAuth';
import { User } from '../../providers/User';

// CSS
import '../../assets/css/LoginModal.css'
import 'react-toastify/dist/ReactToastify.css';
// import 'react-phone-number-input/style.css'
import 'react-phone-input-2/lib/style.css'

// Components
import PhoneInput from 'react-phone-input-2'
import { ToastContainer, toast } from 'react-toastify';

function LoginModal(props) {

    const { showModal, handleModalClose } = props;

  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState(null)
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [remember, setRemember] = useState(false)

  const navigate = useNavigate();

  // Methods
  const handleClose = () => setShow(false);

  const onPhoneChange = (value, country) => {
    const country_code = "+" + country.dialCode;
    const phone = value.toString().replace(country.dialCode, "")
    setPhone(country_code + "-" + phone)
  }

  // Set Login Payload
  const setloginPayload = () => {
        let payload = {}

        payload = {
            detail: phone,
            password: password,
            rememberMe: remember
        }

        login(payload)
    }

    // Login function
    const login = (payload) => {

        payload.password = Crypto.HmacSHA512(payload.password, secret).toString(Crypto.enc.Hex);

        UserAuth.login(payload)
            .then(res => {
                User.setUser(res)
                localStorage.setItem("signupmobileverified", "true")
                localStorage.setItem('x-access-token', res.token)
                toast.success('Logging in. Please wait...')
                setIsLoading(true)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            })
            .catch(err => {
                toast.error(err && err.response ? err.response.data.msg : "Something went wrong", toastStyle);
                setIsLoading(false)
            })
    }

  // Handle Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    // Check phone or password is not empty
    if (!phone || !password) {
        toast.error("All fields are mandatory!", {theme: "colored"});
        setIsLoading(false)
        return
    } else {
        // Login request
        setloginPayload()
    }
  };

  return (
    <>
      <Modal className='login-modal' show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login to Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form id="login-form" onSubmit={handleSubmit}>
                <div className='form-row phone-row'>
                    <PhoneInput
                        country={'us'}
                        value={phone}
                        onChange={(value, country) => onPhoneChange(value, country)}
                        enableSearch={true}
                        placeholder={'Enter your mobile no.'}
                    />
                </div>

                <div className='form-row password-row'>
                    <input 
                        type="password" 
                        name="password" 
                        placeholder="Enter your password" 
                        onChange={(e) => setPassword(e.target.value)}
                        required />
                </div>

                <div className='form-row'>
                    <button disabled={isLoading} type="submit">
                        { isLoading ? 'Loading...' : 'Login' }
                    </button>
                </div>

                <div className='d-flex align-items-center justify-content-between'>
                  <div className='remember-me'><label><input type="checkbox" name="remember_me" /> Remember Me</label></div>
                  <div className='remember-me'><a className='login-bottom-text' href='https://ovato.com/en' target='_blank'>Ovato.com</a></div>
                </div>
                
            </form>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
}

export default LoginModal;