import React, { Component } from 'react';

// Images
import ImgStar from '../../assets/img/home/img-star.png'
import ImgAppStore from '../../assets/img/home/img-app-store.png'
import ImgGooglePlay from '../../assets/img/home/img-google-play.png'
import ImgBannerApp from '../../assets/img/home/img-banner-app.png'

class Section1 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
        <section class="home-1 new-home-2 new-home-2-new">
          <div class="container">
            <div class="row flex align-center home-1-row-2">
              <div class="col-sm-7 home-1-right-col home-1-right-col-new home-1-right-col-new-2">
                <h2 class="poppins v2-font-31 v2-tp-font-28 v2-m-font-24 v2-m-font-20 semi-bold white-text text-center">
                Easiest and Duplicatable opportunity in Crypto !
                </h2>
                
                <ul class=" ul-checklist white-text">
                  <li>Great Compensation Package</li>
                  <li>Royalties Forever </li>
                  <li>Assets that Appreciate</li>
                </ul>

                <div class="new-home-2-rating-wrap justify-center new-home-2-rating-wrap-3">
                  <div class="img-wrap">
                    <img width="100" src={ImgStar} alt="4.8 Star Rating" />
                  </div>
                  <div class="text-wrap">
                    <p class="poppins n-font-16 regular white-text">
                      4.8 stars
                    </p>
                  </div>
                </div>
                
                <div class="flex justify-center row-wrap align-center new-home-2-dl-wrap new-home-2-dl-wrap-2 new-home-2-dl-wrap-3">
                            
                  <div class="img-wrap">
                    <a href="#" class="open-launching-may-popup" rel="noopener noreferrer" target="_blank"><img src={ImgAppStore} alt="App Store" /></a>
                  </div>
                  <div class="img-wrap">
                    <a href="#" class="open-launching-may-popup" rel="noopener noreferrer" target="_blank"><img src={ImgGooglePlay} alt="Google Play" /></a>
                  </div>
                  
                </div>
              </div>
              
              <div class="col-sm-5 relative">
                <div class="img-wrap new-banner-img">
                  <img src={ImgBannerApp} />
                </div>
              </div>
              
            </div>
          </div>
          {/* <!-- end class container --> */}
        </section>
        </>
    );
  }
}

export default Section1;