import axios from 'axios';
import {baseUserAuthApiUrl, baseApiUrl} from '../constants/defaultValues';

const createInstance = function () {
  const axiosInstance = axios.create({
    baseURL: baseApiUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('x-access-token')
    }
  });
  return axiosInstance;
};

const walletInstance = function () {
  const axiosInstance = axios.create({
    baseURL: 'https://api-wallet.ovato.com/',
    // baseURL: baseApiUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('x-access-token')
    }
  });
  return axiosInstance;
};

export const User = {
  setUser: function (data) {
    localStorage.setItem('x-access-token', data.token);
    localStorage.setItem('userInfo', JSON.stringify(data.user));
    localStorage.setItem('login',true)
  },
  settempUser: function (data) {
    localStorage.setItem('tempUser', data.user._id);
  },
  setAssetMap: function (data) {
    localStorage.setItem('assetMap', JSON.stringify(data));
  },
  getAssetMapLocal: () => {
    if (!localStorage.getItem('assetMap')) return null;
    return JSON.parse(localStorage.getItem('assetMap'));
  },
  authorize: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('users/authorize', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  resend_confirmation: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('auth/resendEmail', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  forgot_password: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('password-resets', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  getExchangeRate: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = walletInstance();
      axiosInstance
        .get('wallets/exchRate', payload)
        .then(function (response) {
          localStorage.setItem("ovo-rate",JSON.stringify(response.data))
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  requestPasswordReset: function (payload, token) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .put('password-resets/' + token, payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  
  Two_fa_OTP_verify: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('auth/verifyOtp', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  Two_fa_OTP_resend: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('auth/resendOtp', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  getClientOtp: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('/clients/resendOtp', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  signup: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('auth/signUp', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  login: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('auth', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },


  confirmEmail: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('auth/verifyEmail', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },


  getWallet: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('wallets')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getWords: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('users/seeds')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createWallet: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('wallets', payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  sendOvato: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('wallets/forward', payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getDetailsByAddress: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('wallets/getAddressData', payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRating: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('wallets/getRating', payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getWalletTransactions: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('wallets/transactions')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getWalletBalance: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('wallets/balance')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getWalletAddress: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`wallets/getAddress/${payload}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getWalletAddressUpdate: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('wallets/getAddressUpdated')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  delete_wallet: id => {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .put(`wallets/delete_wallet/${id}`)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  updateWallet: function (id, payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .put(`wallets/update_wallet/${id}`, payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  validateOtp: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('users/otp', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  validateClientOtp: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('clients/otp', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  validateSignupOTP: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('users/verifySignUp', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  CreateLeadStep2: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('bills/', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  CreateLeadStep3: function (payload) {
    return new Promise(function (resolve, reject) {
      const axiosInstance = createInstance();
      axiosInstance
        .post('sites/', payload)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },

  gettempuser: function () {
    if (!localStorage.getItem('tempUser')) return null;
    return localStorage.getItem('tempUser');
  },
  getUserLocal: function () {
    if (!localStorage.getItem('userInfo')) return null;
    return JSON.parse(localStorage.getItem('userInfo'));
  },
  setToken: function (token) {
    localStorage.setItem('x-access-token', token);
  },
  hasToken: function () {
    if (localStorage.getItem('x-access-token')) return true;
    else return false;
  },

  removeStorage: function () {
    localStorage.clear();
  },
  UpdateStep2: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('users/basic', payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UpdateEPCStep3: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('users/details', payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  CreateLeadStep1: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('/leads', payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getDiscom: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('/bills/discom')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAssetMap: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('assets/getAssetMap')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getUser: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('users/me')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  confirmBackup: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('users/backup')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getLTP: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`matches/getLTP`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getGlobalLeaderboard: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`leagues/global-leaderBoard/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAssetLTP: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`matches/getLTP?assetId=${payload}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAssetVital: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/assets/getAssetVitals/${payload}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getOrder: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`orders/${payload}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateOpenOrder: (id, payload) => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`orders/${id}`, payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateProfile: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .put('users/me', payload)
        .then(response => {
          localStorage.setItem(
            'userInfo',
            JSON.stringify(response.data)
          );
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateProfilePic: payload => {
    return new Promise(function (resolve, reject) {
      // const axiosInstance = createInstance();
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("x-access-token"),
          "content-type": "multipart/form-data"
        }
      };

      const formdata = new FormData();
      for (let i = 0; i < payload.length; i++) {
        formdata.append("__files", payload[i]);
      }

      axios
        .post('users/profile_pic', formdata, config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  placeOrder: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('orders/', payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  joinLeague: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('user-contests/', payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  maketeam: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('teams', payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  editteam: (id, payload) => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`teams/${id}`, payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getOpenOrder: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('orders')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTrades: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`matches/${payload}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getLeaderboard: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/leagues/leaderBoard/${payload}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getOhlc: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`matches/get_ohlc/${payload}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getMyTrades: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('matches')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTeams: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`teams/${payload}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAllTeams: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`teams/`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  uploadImage: function (payload) {
    return new Promise(function (resolve, reject) {
      // const axiosInstance = createInstance();
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("x-access-token"),
          "content-type": "multipart/form-data"
        }
      };

      const formdata = new FormData();
      for (let i = 0; i < payload.length; i++) {
        formdata.append("__files", payload[i]);
      }

      axios
        .post(`${baseUserAuthApiUrl}users/profile_pic`, formdata, config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  getStacks: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('stacks/')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getMyStack: payload => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('stacks/mystack')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createStack: function (id,payload) {
    return new Promise(function (resolve, reject) {
        const axiosInstance = createInstance();
        axiosInstance.post(`stacks/${id}`,payload)
        .then(function (response) {
            resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  cancelStack: id => {
    const axiosInstance = createInstance();
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`stacks/cancel/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getRatesFromLocalStorage: function () {
    if (!localStorage.getItem('ovo-rate')) return null;
    return localStorage.getItem('ovo-rate');
  },

};