import React, { Component } from 'react';

// Components
import Header from '../components/header/Header2';
import Footer from '../components/footer/Footer2';

import record from '../data/walletCompensation'

class WalletCompensationPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: record,
      currentPage: 1,
      pageSize: 63, // Number of records per page
      searchQuery: '' // To handle search input
    };
  }

   // Method to get the paginated data based on the current page and search query
  paginate = (array, page_size, current_page) => {
    const start = (current_page - 1) * page_size;
    const end = start + page_size;
    return array.slice(start, end);
  };

   // Method to handle the page change
   handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  // Method to handle the search query
  handleSearch = (event) => {
    this.setState({
      searchQuery: event.target.value,
      currentPage: 1 // Reset to page 1 on search
    });
  };

  // Method to filter data based on search query
  filterData = (data, query) => {
    if (!query) {
      return data;
    }
    return data.filter((record) =>
      record.country_name.toLowerCase().includes(query.toLowerCase())
    );
  };

  render() {
    const { data, currentPage, pageSize, searchQuery } = this.state;

    // Filter data based on search query
    const filteredData = this.filterData(data, searchQuery);

    // Get paginated data for the current page
    const paginatedData = this.paginate(filteredData, pageSize, currentPage);

    // Calculate total pages based on filtered data
    const totalPages = Math.ceil(filteredData.length / pageSize);
    return (
        <>
            <Header />

            {/* Section 2 */}
            <section class="first-row top-curve top-curve-7 rules-2 pt-12 new-last-section v2-tl-pt-10 v2-m-pt-0">
              <div class="container">
                <div class="row">
                  <div class="col">

                    <h1 class="poppins v2-font-35 v2-m-font-24 bold dark-text-2 mb-10 tools-3-heading v2-mt-2 v2-m-mt-1">
                      <strong class="">Wallet Compensation Plan</strong>
                    </h1>

                    {/* Search Box */}

                    <div className='wcp-input-wrap'>
                      <input
                        type="text"
                        placeholder="Search by Country Name..."
                        value={searchQuery}
                        onChange={this.handleSearch}
                      />
                    </div>
                    
                    <div class="leaderboard-body wcp-body" id="wcp-body">
                        <div class="leaderboard-tbl-wrap">
                            <div class="leaderboard-tbl-header">
                                <div>
                                    Country Name
                                </div>
                                <div>
                                    Merchant Wallet Bounty $
                                </div>
                                <div>
                                    User Wallet Bounty $
                                </div>
                            </div>
                            {/* <!-- end class leaderboard-tbl-header --> */}
                            
                            <div class="leaderboard-tbl-body" id="wcp-tbl-body">
                                {paginatedData.length > 0 ? (
                                  paginatedData.map((item, index) => (
                                      <div className="leaderboard-tbl-body-row" key={item.id}>
                                          <div data-label="Country Name">{((currentPage - 1) * pageSize) + index + 1}. {item.country_name}</div>
                                          <div data-label="Merchant Wallet Bounty $">{`$${item.merchant_wallet_bounty}`}</div>
                                          <div data-label="User Wallet Bounty $">{`$${item.user_wallet_bounty}`}</div>
                                      </div>
                                  ))
                                ) : (
                                  <p className='v2-mt-2 v2-font-14 text-center'>No records found.</p>
                                )}
                            </div>{/* <!-- end class leaderboard-tbl-body --> */}
                            { paginatedData.length > 0 && (
                              <div class="leaderboard-footer">
                                <ul class="leaderboard-pagination" id="wcp-pagination">
                                    {/* Previous Button */}
                                    <li>
                                      <a 
                                        onClick={() => this.handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        class="leaderboard-pagination-previous" 
                                        id="wcp-pagination-previous" 
                                        href="#"
                                        >&lt;
                                      </a>
                                    </li>
                                    {/* Page Numbers */}
                                      {[...Array(totalPages)].map((_, pageIndex) => (
                                          <li>
                                            <a 
                                            href="#" 
                                          key={pageIndex} 
                                          onClick={() => this.handlePageChange(pageIndex + 1)}
                                          className={currentPage === pageIndex + 1 ? 'leaderboard-pagination-num active' : 'leaderboard-pagination-num'}
                                          >{pageIndex + 1}
                                          </a>
                                          </li>
                                      ))}
                                    
                                    {/* Next Button */}
                                    <li>
                                      <a 
                                        onClick={() => this.handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                        class="leaderboard-pagination-next" 
                                        id="wcp-pagination-next" 
                                        href="#"
                                      >&gt;</a>
                                    </li>
                                  </ul>
                              </div>
                            )}
                        </div>{/* <!-- end class leaderboard-tbl-wrap --> */}
                    </div>

                  </div>
                </div>
              </div>
            </section>
            
            <Footer />
        </>
    );
  }
}

export default WalletCompensationPlan;