import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

class ModalVideoManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen } = this.state;
    const { videoId } = this.props;

    return (
      <>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId={videoId}
          onClose={this.handleClose}
        />
        {this.props.render({
          isOpen,
          handleOpen: this.handleOpen,
          handleClose: this.handleClose,
        })}
      </>
    );
  }
}

export default ModalVideoManager;