import React, { Component } from 'react';

import { User } from '../../providers/User';

class TopMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
        
    };
    
  }

  render() {

    const { t, handleNavigation, handleLogout } = this.props;

    return (
        <>
            <ul class="site-menu site-menu-2">
                <li><a href="/how-it-works">How it Works</a></li>
                <li><a href="/compensation-plan" onClick={(e) => handleNavigation(e, "compensation-plan") }>Compensation Plan</a></li>
                <li><a href="/nfts" onClick={(e) => handleNavigation(e, "nfts") }>NFTs</a></li>
                <li><a href="/tools" onClick={(e) => handleNavigation(e, "tools") }>Tools</a></li>
                <li><a href="/rules" onClick={(e) => handleNavigation(e, "rules") }>Rules</a></li>
                <li><a href="/get-started" onClick={(e) => handleNavigation(e, "get-started") }>Get Started</a></li>
                { User.hasToken() && <li><a href="#" onClick={() => handleLogout() }>Logout</a></li>      }
            </ul>
        </>
    );
  }
}

export default TopMenu;