import React, { Component } from 'react';

class RadioTime extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { text, value, selected, handleChange } = this.props

    return (
        <div className='v3-radio-time-wrap'>
            <label className='v3-radio-time-label'>
                <input type="radio" value={value} name="time" className='v3-radio-time-input' checked={selected == value} onChange={() => handleChange(value)}/> <span className='v3-radio-time-text'>{text}</span>
            </label>
        </div>
    );
  }
}

export default RadioTime;