import React, { Component } from 'react';

import Slider from 'react-slick';

// Hooks
import ModalVideoManager from '../components/modal/ModalVideoManager';

// Slick Slider
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Components
import Header from '../components/header/Header2';
import Footer from '../components/footer/Footer';

// CSS 
import '../assets/css/HowItWorks2.css'

// Images
import ImgVideo from '../assets/img/how-it-works-2/img-video-1.jpg'
import ImgWheel from '../assets/img/how-it-works-2/img-wheel.png'

import ImgProbit from '../assets/img/how-it-works-2/img-probit.png'
import ImgP2P from '../assets/img/how-it-works-2/img-p2p.png'
import ImgLBank from '../assets/img/how-it-works-2/img-lbank.png'
import ImgBinance from '../assets/img/how-it-works-2/img-binance.png'
import ImgOpenSea from '../assets/img/how-it-works-2/img-opensea.png'
import ImgCoinbase from '../assets/img/how-it-works-2/img-coinbase.png'

class HowItWorks2 extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
        <>
            <Header />
            {/* Section 1 */}1
            <section class="first-row v2-pb-12 v2-tp-pt-12 v2-m-pt-10 v2-tp-pb-0 v2-hiw-1">
              <div className='container'>
                <div className='row'>
                    <h1 class="poppins text-center n-font-39 v2-tl-font-36 bold dark-text-2 mb-10 white-text hiw-1-heading ">
                      <strong class="double-underline double-underline-heading">How it Works!</strong>
                    </h1>
                </div>

                <div className='v2-mt-3 v2-mw-1000 align-center mx-auto row'>
                    <div className='col'>
                        <div className='v2-hiw-1-box'>
                            <h2 className='v2-hiw-step'>Step 1</h2>
                            <p className='poppins v2-font-24 v2-tl-font-22 regular white-text lh-1-3 v2-hiw-1-text'>Give out as many wallets as you can, and <span className='orange-text-3 v2-hiw-text-line'>build your base.</span></p>
                        </div>
                    </div>

                    <div className='col'>
                        <ModalVideoManager videoId="Egv0aFgcq34" render={({ handleOpen }) => (
                            <>
                                <div className='v2-ml--4 v2-tp-mx-auto v2-tp-mw-100 img-wrap'>
                                    <img className='video-wrap' onClick={handleOpen} src={ImgVideo} />
                                </div>
                            </>
                        )}/>
                    </div>
                </div>
              </div>
            </section>

            {/* Section 2 */}
            <section className='v2-pt-3 v2-hiw-2'>
                <div className='container'>
                    <div className='v2-mw-1000 mx-auto align-center row'>
                        <div className='col col-sm-5'>
                            <div className='v2-hiw-2-box'>
                                <h2 className='v2-mt-1 v2-hiw-step'>Step 2</h2>

                                <div className='v2-hiw-2-box-body'>
                                    <h3 className='poppins v2-font-24 semi-bold white-text'><span className='orange-text-3 v2-hiw-text-line'>Earn and Grow</span> <br /> your Money!</h3>

                                    <p className='poppins v2-font-17 regular white-text'>Multiple income streams</p>

                                    <ul className='ul-checklist white-text'>
                                        <li>Wallet Openings</li>
                                        <li>Transactional Royalties</li>
                                        <li>NFT Sales</li>
                                    </ul>

                                    <p className='poppins v2-font-17 regular white-text'>Plus much more!</p>
                                </div>

                            </div>
                        </div>

                        <div className='col col-sm-7 v2-pl-2 v2-m-pl-1'>
                            <div className='v2-mr--10 v2-tl-mr-0 v2-tp-mx-auto v2-tp-mt-4 v2-m-mt-3 v2-tp-mw-70 v2-m-mw-100 img-wrap'>
                                <img src={ImgWheel} />
                            </div>
                        </div>
                    </div>

                    <div className='v2-mt-6 v2-m-mt-3 v2-mw-1000 mx-auto row'>
                        <div className='v2-hiw-2-box-2'>
                            <h2 class="poppins n-font-39 v2-tl-font-36 v2-tp-font-32 v2-m-font-28 v2-m-text-center bold dark-text-2 mb-10 white-text hiw-1-heading "><strong class="double-underline double-underline-heading">Appreciable</strong></h2>
                            <ul className='v2-mt-2 ul-checklist white-text v2-m-mt-14 v2-hiw-2-box-2-ul'>
                                <li>Ovato has a fixed supply - <br /> more users - higher price</li>
                                <li>NFTs <br /> More activity - higher price </li>
                                <li>Baking <br /> Earn holding, and price protection</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* Section 3 */}
            <section className='top-curve v2-pt-12 v2-pb-6 v2-hiw-3-curve v2-hiw-3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>

                            <div className='text-center'>
                                <h2 className='v2-mt-1 v2-hiw-step'>Step 3</h2>
                            </div>

                            <h3 class="v2-mt-2 v2-m-mt-0 poppins text-center n-font-39 v2-tl-font-36 v2-tp-font-32 bold dark-text-2 mb-10">
                                <strong class="double-underline double-underline-heading">Exit Ecosystem</strong>
                            </h3>

                        </div>
                    </div>

                    <div className='row'>
                        <div className='v2-mt-4 col col-sm-6'>
                            <div className='v2-hiw-3-box'>

                                <div className='v2-hiw-3-subheading-wrap'>
                                    <div className='v2-hiw-3-subheading'>Ovato Coins</div>
                                </div>
                                
                                <p className='poppins v2-font-27 bold dark-text-2 text-center v2-mt-3 lh-1-2 v2-m-font-24'>Secondary markets</p>

                                <p className='poppins v2-font-18 v2-m-font-17 regular text-center dark-text-2 v2-mb-0'>Exchanges</p>
                                <p className='poppins v2-font-18 v2-m-font-17 regular text-center dark-text-2 v2-mb-0'>Money service providers</p>
                                <p className='poppins v2-font-18 v2-m-font-17 regular text-center dark-text-2 v2-mb-0'>Peer to peer</p>

                                <div className='v2-mt-2 v2-mw-90 mx-auto row'>
                                    <div className='col'>
                                        <div className='img-wrap v2-m-mw-80'>
                                            <a href='https://www.probit.com/en-us/' target='_blank' rel="noopener noreferrer"><img src={ImgProbit} /></a>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='img-wrap v2-m-mw-80'>
                                            <a href='https://p2pb2b.com/' target='_blank' rel="noopener noreferrer"><img src={ImgP2P} /></a>
                                        </div>
                                    </div>
                                </div>

                                <div className='v2-mw-90 mx-auto row'>
                                    <div className='col'>
                                        <div className='img-wrap v2-m-mw-80'>
                                            <a href='https://www.lbank.com/' target='_blank' rel="noopener noreferrer"><img src={ImgLBank} /></a>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='relative img-wrap v2-tp-mw-50 v2-m-mw-80'>
                                            <a href='https://www.binance.com/en' target='_blank' rel="noopener noreferrer"><img src={ImgBinance} /></a>
                                            <div className='v2-hiw-2-coming-soon'>Coming Soon</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='v2-mt-4 col col-sm-6'>
                            <div className='v2-hiw-3-box'>

                                <div className='v2-hiw-3-subheading-wrap'>
                                    <div className='v2-hiw-3-subheading'>Whole NFT</div>
                                </div>
                                
                                <p className='poppins v2-font-27 bold dark-text-2 text-center v2-mt-3 v2-mb-0 lh-1-2 v2-m-font-24'>Business in a box</p>

                                <p className='poppins v2-font-18 v2-m-font-17 regular text-center dark-text-2 v2-mb-0 lh-1-3 v2-mt-1'>Sell your NFT has a multiple of your efforts</p>

                                <div className='v2-mt-2 v2-mw-90 mx-auto row'>
                                    <div className='col'>
                                        <div className='relative text-center img-wrap v2-mw-50 v2-m-mw-80 mx-auto'>
                                            <a href='https://opensea.io/' target='_blank' rel="noopener noreferrer"><img src={ImgOpenSea} /></a>
                                            <div className='v2-hiw-2-coming-soon v2-hiw-2-coming-soon-top'>Coming Soon</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='v2-mw-90 mx-auto row'>
                                    <div className='col'>
                                        <div className='relative text-center img-wrap v2-mw-50 v2-m-mw-80 mx-auto'>
                                            <a href='https://p2pb2b.com/' target='_blank' rel="noopener noreferrer"><img src={ImgP2P} /></a>
                                            <div className='v2-hiw-2-coming-soon v2-hiw-2-coming-soon-top'>Coming Soon</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='v2-mw-90 mx-auto row'>
                                    <div className='col'>
                                        <div className='relative text-center img-wrap v2-mw-50 v2-m-mw-80 mx-auto'>
                                            <a href='https://www.coinbase.com/' target='_blank' rel="noopener noreferrer"><img src={ImgCoinbase} /></a>
                                            <div className='v2-hiw-2-coming-soon v2-hiw-2-coming-soon-top'>Coming Soon</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col'>
                            <div class="btn-wrap home-3-btn-wrap"><a href="#" class="site-btn site-btn-2 site-btn-subtext signup-v3-btn">compensation plan</a></div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
  }
}

export default HowItWorks2;