import React, { Component } from 'react';

class FooterCopyright extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const currentYear = new Date().getFullYear();

    return (
        <>
            <ul className='footer-copyright'>
                <li>
                    <a href='#'>Privacy Policy</a>
                </li>
                <li>
                    <a href='#'>Terms and Condition</a>
                </li>
                <li>
                    <a href='#'>Copyright © 2018 - {currentYear} Ovato</a>
                </li>
                <li>
                    All Rights Reserved
                </li>
            </ul>
        </>
    );
  }
}

export default FooterCopyright;