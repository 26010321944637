import React, { Component } from 'react';

// Hooks
import ModalVideoManager from '../components/modal/ModalVideoManager';

// Components
import Header from '../components/header/Header2';
import Footer from '../components/footer/Footer2';

// Images
import ImgNFT2 from '../assets/img/nft/img-nft-2.jpg'
import ImgPlayButtonBig from '../assets/img/nft/img-play-button-big.png'
import ImgPlayVideo from '../assets/img/nft/img-play-video-text-bottom.png'

import ImgType1 from '../assets/img/nft/img-type-01.png'
import ImgType2 from '../assets/img/nft/img-type-02.png'
import ImgType3 from '../assets/img/nft/img-type-03.png'

class NFTs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
            <Header />
            {/* SECTION  1 */}
            <section class="nft-1">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="mb-0 poppins n-font-27 bold blue-text text-center">
                      Grow your business inside your own NFT Back by real world assets!
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* SECTION 2 */}
            <section class="nft-2 v2-nft-2">
              <div class="container">
                <div class="row">
                  <div class="col col-sm-6">
                    <h1 class="n-font-45 poppins semi-bold white-text nft-2-heading">
                      Top 5 Reasons <br /> to use an NFT
                    </h1>
                    
                    <ul class="ul-checklist white-text nft-2-ul">
                      <li>Earn on Level 2 Transactions</li>
                      <li>Each NFT has real world assets</li>
                      <li>Ability to grow and sell your business at a multiple</li>
                      <li>Ability to capture royalties inside NFT</li>
                      <li>Ability to have your business grow above your assets through appreciation and security</li>
                      <li>Exit through secondary NFT Market of your NFT.</li>
                    </ul>
                  </div>
                  
                  <div class="col col-sm-6">
                  <ModalVideoManager videoId="9Vbm7qM9Hzw" render={({ handleOpen }) => (
                        <>
                            <div class="video-wrap c-video-wrap c-video-wrap-full">
                              <div class="c-video-img-wrap">
                                <img className='video-wrap' onClick={handleOpen} src={ImgNFT2} />
                              </div>

                              <div class="c-video-play-btn c-video-play-btn-full">
                                <a><img className='video-wrap' onClick={handleOpen} src={ImgPlayButtonBig} /></a>
                              </div>

                              <div class="c-video-play-text c-video-play-text-bottom">
                                <img class="desktop" src={ImgPlayVideo} />
                                <img class="mobile" src={ImgPlayVideo} />
                              </div>
                            </div>
                        </>
                    )}/>
                    
                  </div>
                </div>
              </div>
            </section>

            {/* Section 3 */}
            <section class="nft-3 new-last-section">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <h2 class="poppins n-font-35 semi-bold dark-text-2 text-center v2-mb-1 nft-3-heading">
                      3 Distinct types of Sizes
                    </h2>
                  </div>
                </div>
                
                {/* <!--  SMALL  --> */}
                <div class="row nft-3-row">
                  <div class="col col-sm-5 nft-3-left-col">
                    <div class="row align-center">
                      <div class="col">
                        <div class="img-wrap">
                          <img width="175" src={ImgType1} />
                        </div>
                      </div>
                      
                      <div class="col">
                        <h3>
                          Small $1500
                        </h3>
                        
                        <ul>
                          <li>Capacity: 25,000</li>
                          <li>Included: 5000 OVO</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col col-sm-4 nft-3-mid-col">
                    <h4>
                      Earnings Level 1
                    </h4>
                    
                    <ul>
                      <li>Wallets: $2 - $20</li>
                      <li>Membership: 25%</li>
                      <li>NFTs: 25%</li>
                      <li>Merchants: $100</li>
                      <li>Royalties: 15%</li>
                    </ul>
                  </div>
                </div>
                  
                {/* <!--  MEDIUM  --> */}
                <div class="row nft-3-row">
                  <div class="col col-sm-5 nft-3-left-col">
                    <div class="row align-center">
                      <div class="col">
                        <div class="img-wrap">
                          <img width="175" src={ImgType2} />
                        </div>
                      </div>
                      
                      <div class="col">
                        <h3>
                          Medium $3000
                        </h3>
                        
                        <ul>
                          <li>Capacity: 100,000</li>
                          <li>Included: 12,000 OVO</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col col-sm-4 nft-3-mid-col">
                    <h4>
                      Earnings Level 1
                    </h4>
                    
                    <ul>
                      <li>Wallets: $2 - $20</li>
                      <li>Membership: 25%</li>
                      <li>NFTs: 25%</li>
                      <li>Merchants: $100</li>
                      <li>Royalties: 15%</li>
                    </ul>
                  </div>
                  
                  <div class="col nft-3-right-col">
                    <h4>
                      Earnings Level 2
                    </h4>
                    
                    <ul>
                      <li>Wallets: $3</li>
                      <li>Membership: 20%</li>
                      <li>NFTs: 20%</li>
                      <li>Merchants: $25</li>
                      <li>Royalties: 30%</li>
                    </ul>
                  </div>
                </div>
                
                {/* <!--  LARGE  --> */}
                <div class="row nft-3-row">
                  <div class="col col-sm-5 nft-3-left-col">
                    <div class="row align-center">
                      <div class="col">
                        <div class="img-wrap">
                          <img width="175" src={ImgType3} />
                        </div>
                      </div>
                      
                      <div class="col">
                        <h3>
                          Large $5000
                        </h3>
                        
                        <ul>
                          <li>Capacity: 400,000</li>
                          <li>Included: 25,000 OVO</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col col-sm-4 nft-3-mid-col">
                    <h4>
                      Earnings Level 1
                    </h4>
                    
                    <ul>
                      <li>Wallets: $2 - $20</li>
                      <li>Membership: 25%</li>
                      <li>NFTs: 25%</li>
                      <li>Merchants: $100</li>
                      <li>Royalties: 15%</li>
                    </ul>
                  </div>
                  
                  <div class="col nft-3-right-col">
                    <h4>
                      Earnings Level 2
                    </h4>
                    
                    <ul>
                      <li>Wallets: $4</li>
                      <li>Membership: 25%</li>
                      <li>NFTs: 25%</li>
                      <li>Merchants: 30%</li>
                      <li>Royalties: 35%</li><li>
                    </li></ul>
                  </div>
                </div>
                
                <div class="row v2-mw-1200 mx-auto">
                  <div class="col">
                    <p class="poppins v2-font-16 v2-m-font-14 regular gray-text-5 v2-mt-2">
                      * all royalties are based on one percent gross-see terms and conditions for more details
                    </p>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col">
                    <div class="text-center mt-40 btn-wrap">
                      <a href="#" class="site-btn site-btn-2 site-btn-subtext  signup-v3-btn">
                        Proceed to NFTs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
        </>
    );
  }
}

export default NFTs;