import React, { Component } from 'react';

// Components
import Header from '../components/header/Header2';
import Footer from '../components/footer/Footer2';

// Images
import ImgRules from '../assets/img/rules/img-rules-banner.png'

class Rules extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
            <Header />

            {/* Section 1 */}
            <section class="pt-16 pb-2 rules-1 tp-pt-8">
              <div class="container">
                <div class="row align-center">
                  <div class="col px-8 py-4 col-sm-6 m-p-2">
                    <h1 class="poppins n-font-39 bold dark-text-2 mb-10 tools-3-heading text-center">
                      <strong class="double-underline double-underline-heading">Rules</strong>
                    </h1>
                    
                    <p class="poppins n-font-17 m-font-15 regular gray-text-2 mt-20 tools-3-subheading-new">
                      We really only have three rules but it’s important for you to follow them. this is how we are able to pay you so well and protect the economy and its stakeholders
                    </p>
                  </div>
                  <div class="col col-sm-6">
                    <div class="img-wrap">
                      <a href="#">
                        <img width="469" src={ImgRules} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Section 2 */}
            <section class="top-curve top-curve-7 rules-2 pt-12 tl-pt-8 tp-pt-6 m-pt-5 new-last-section">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="poppins font-17 m-font-16 regular text-center gray-text-4 mw-45 mx-auto m-mw-100">
                      How we enforce this, for everyone is just to have a minimum activity level to ensure your royalty payment
                    </p>
                    
                    <div class="rules-box">
                      <h2 class="rules-box-h2">
                        <span>Rules</span>
                      </h2>
                      
                      <ul class="rules-box-ul">
                        <li>Give out 3 wallets per month</li>
                        <li>Like, comment, or share on any social media once per month</li>
                        <li>Personalize your wallet and contribute in its features to build and profit in your business</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            
            <Footer />
        </>
    );
  }
}

export default Rules;