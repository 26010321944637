import React, { Component } from 'react';

// Hooks
import ModalVideoManager from '../modal/ModalVideoManager';

// CSS 
import '../../assets/css/Tools.css'

// Images
import ImgVideoTools from '../../assets/img/tools/img-video-tools-2.jpg'
import ImgNewsletter from '../../assets/img/tools/img-newsletter-and-telegram.png'

import ImgProfile from '../../assets/img/tools-logged-out/img-profile.png'
import ImgEarnings from '../../assets/img/tools-logged-out/img-earnings.png'
import ImgEarningsMobile from '../../assets/img/tools-logged-out/img-earnings-mobile.png'
import ImgTotalOrg from '../../assets/img/tools-logged-out/img-total-org.png'
import ImgTotalWallets from '../../assets/img/tools-logged-out/img-total-wallets.png'
import ImgTotalLevel1 from '../../assets/img/tools-logged-out/img-total-level-1.png'
import ImgTotalLevel2 from '../../assets/img/tools-logged-out/img-total-level-2.png'

import ImgVideo1 from '../../assets/img/tools-logged-out/img-video-1.jpg'
import ImgVideo2 from '../../assets/img/tools-logged-out/img-video-2.jpg'
import ImgVideo3 from '../../assets/img/tools-logged-out/img-video-3.jpg'
import ImgVideo4 from '../../assets/img/tools-logged-out/img-video-4.jpg'
import ImgVideo5 from '../../assets/img/tools-logged-out/img-video-5.jpg'
import ImgVideo6 from '../../assets/img/tools-logged-out/img-video-6.jpg'

import ImgPersonalized from '../../assets/img/tools-logged-out/img-personalized-qr-codes.png'
import ImgPersonalizedMobile from '../../assets/img/tools-logged-out/img-personalized-mobile.png'
import ImgPersonalizedMobile2 from '../../assets/img/tools-logged-out/img-personalized-mobile-2.png'

import ImgPromotional from '../../assets/img/tools-logged-out/img-promotional-products.png'
import ImgPromotionalMobile from '../../assets/img/tools-logged-out/img-promotional-products-mobile.png'

class ToolsLoggedOut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    
    return (
        <>
            <section class="tools-3 new-last-section">
              <div class="container">
                <div class="row align-center">
                  <div class="col px-8 py-4 col-sm-6 m-p-2">
                    <h1 class="poppins n-font-39 bold dark-text-2 mb-10 tools-3-heading">
                      <strong class="double-underline double-underline-heading">Tools</strong>
                    </h1>
                    
                    <p class="poppins n-font-17 m-font-16 regular gray-text-2 mt-20 tools-3-subheading-new">
                      We provide you will all the tools necessary to grow your business and plan if ever you sell your business.
                    </p>
                  </div>
                  <div class="col col-sm-6">
                    <div class="img-wrap">
                      <ModalVideoManager videoId="XVoDr9-XOgs" render={({ handleOpen }) => (
                          <>
                            <img className='video-wrap' onClick={handleOpen} src={ImgVideoTools} />
                          </>
                        )}/>
                    </div>
                  </div>
                </div>
                
                {/* 1 */}
                <div class="row tools-3-row">
                  <div class="col v2-pb-0 tools-3-col tools-3-col-1 v2-m-px-0">
                    <div class="tools-3-num">
                      1
                    </div>
                    
                    <h2 class="tools-3-col-heading">
                      Profile
                    </h2>
                    
                    <div class="img-wrap v2-mb-0 text-center">
                      <img src={ImgProfile} />
                    </div>
                  </div>
                </div>
                
                {/* 2 */}
                <div class="row tools-3-row">
                  <div class="col v2-pb-0 v2-m-pb-3 tools-3-col tools-3-col-2">
                    <div class="tools-3-num">
                      2
                    </div>
                    
                    <h2 class="tools-3-col-heading">
                      Earnings
                    </h2>
                    
                    <div class="row align-center">
                      <div class="col">
                        <div class="desktop img-wrap v2-mb-0 text-center">
                            <img src={ImgEarnings} />
                        </div>
                        <div class="mobile v2-m-mt-1 img-wrap v2-mb-0 text-center">
                            <img src={ImgEarningsMobile} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* 3 */}
                <div class="row tools-3-row">

                  <div class="col v2-pb-3 tools-3-col tools-3-col-2 tools-3-col-3">

                    <div class="tools-3-num">
                      3
                    </div>

                    <h2 class="tools-3-col-heading">
                      My Organization
                    </h2>

                    <div className='v2-mt-3 v2-tp-mt-0 row'>
                        <div className='col col-sm-6 col-md-3 v2-tp-mt-2 v2-m-mt-1'>
                            <img src={ImgTotalOrg} />
                        </div>

                        <div className='col col-sm-6 col-md-3 v2-tp-mt-2 v2-m-mt-1'>
                            <img src={ImgTotalWallets} />
                        </div>

                        <div className='col col-sm-6 col-md-3 v2-tp-mt-2 v2-m-mt-1'>
                            <img src={ImgTotalLevel1} />
                        </div>

                        <div className='col col-sm-6 col-md-3 v2-tp-mt-2 v2-m-mt-1'>
                            <img src={ImgTotalLevel2} />
                        </div>
                    </div>

                  </div>
                </div>
                
                {/* 4 */}
                <div class="row tools-3-row">
                  <div class="col v2-pb-3 tools-3-col tools-3-col-2 tools-3-col-3">
                    <div class="tools-3-num">
                      4
                    </div>

                    <h2 class="tools-3-col-heading">
                      Videos
                    </h2>

                    <div className='row'>
                        <div className='v2-mt-1 col col-sm-4'>
                            <img src={ImgVideo1} />
                        </div>

                        <div className='v2-mt-1  col col-sm-4'>
                            <img src={ImgVideo2} />
                        </div>

                        <div className='v2-mt-1  col col-sm-4'>
                            <img src={ImgVideo3} />
                        </div>

                        <div className='v2-mt-1 col col-sm-4'>
                            <img src={ImgVideo4} />
                        </div>

                        <div className='v2-mt-1 col col-sm-4'>
                            <img src={ImgVideo5} />
                        </div>

                        <div className='v2-mt-1 col col-sm-4'>
                            <img src={ImgVideo6} />
                        </div>
                    </div>
                  </div>
                </div>
                
                {/* 5 */}
                <div class="row tools-3-row">
                  <div class="col tools-3-col v2-tools-3-col-5">
                    <div class="tools-3-num">
                      5
                    </div>

                    <h2 class="tools-3-col-heading">
                        Personalized QR codes
                    </h2>

                    <div class="row align-center">
                      <div class="col">
                        <div className='img-wrap'>
                            <img className='desktop' src={ImgPersonalized} />
                            
                            <img className='mobile' src={ImgPersonalizedMobile} />
                        </div>

                        <div className='v2-mt--4 mobile'>
                            <img src={ImgPersonalizedMobile2} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* 6 */}
                <div class="row tools-3-row">
                  <div class="v2-pb-0 v2-m-pb-2 col tools-3-col tools-3-col-6">
                    <div class="tools-3-num">
                      6
                    </div>

                    <h2 class="tools-3-col-heading">
                        Promotional Products
                    </h2>

                    <div class="text-center img-wrap v2-mb-0">
                      <img className='desktop' width={600} src={ImgPromotional} />
                      <img className='mx-auto mobile' width={200} src={ImgPromotionalMobile} />
                    </div>
                  </div>
                </div>

              </div>
            </section>
        </>
    );
  }
}

export default ToolsLoggedOut;