import React, { Component } from 'react';

// Images
import ImgRocketLeft from '../../assets/img/home/img-rocket-left.png'
import ImgRocketMobile from '../../assets/img/home/img-rocket-mobile.png'

class Section6 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
        <section class="home-2 new-home-5 new-home-55">
            <div class="container">
                <div class="row align-center opportunity-row">
                    <div class="col-sm-6 opportunity-left-col">
                        <div class="img-wrap opportunity-img-wrap new-home-4-img-text-wrap-1">
                            <img class="desktop" src={ImgRocketLeft} alt="You Level 1 and Level 2" />
                            
                            <img width="250" class="mobile" src={ImgRocketMobile} alt="You Level 1 and Level 2" />
                        </div>
                    </div>
                    <div class="col-sm-6 opportunity-right-col">
                        <h2 class="n-font-48 lh-1">
                            <strong class="double-underline double-underline-2 double-underline-heading">Get Ready </strong>
                        </h2>
                        
                        <p class="poppins n-font-38 lh-1-2 bold dark-text-3 l-font-28 m-font-20">Super affiliates <br /> earn 2 levels deep</p>

                        <ul class="v2-mt-2 ul-checklist ul-oppor">
                            <li>Earn on all activities 2 levels</li>
                            <li>Worldwide Royalties on all activities</li>
                            <li>Earn Forever!</li>
                        </ul>
                    </div>
                </div>
                {/* <!-- end class row --> */}
            </div>
            {/* <!-- end class container --> */}
        </section>
        </>
    );
  }
}

export default Section6;