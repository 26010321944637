import React, { Component } from 'react';

import imgUnderline from '../../assets/img/popup/fi-popup-underline.png'

class FinancePopup extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { show, handleClose } = this.props;

    return (
        <div id="finance-it-popup" class={`suc-popup-wrap suc-popup-2 ${show ? 'active' : ''}`}>
            <div class="suc-popup-overlay" onClick={handleClose}>
            </div>
            <div class="suc-popup-inner">
                <div class="suc-close" onClick={handleClose}>
                    <i class="fas fa-times"></i>
                </div>
                
                <div class="fi-popup-header">
                    <div class="fi-popup-header-main">
                        <p class="poppins v2-font-26 v2-tp-font-22 v2-m-font-20 bold white-text lh-1-2 mb-0">
                            Need Help
                        </p>

                        <p class="poppins v2-font-36 v2-tp-font-28 v2-m-font-24 bold white-text lh-1-2 mb-0">
                            Finance Now!
                        </p>

                        <div class="img-wrap fi-header-img-wrap mb-0">
                            <img src={imgUnderline} />
                        </div>
                    </div>
                    
                </div>
                {/* <!-- end class wc-popup-header --> */}
                
                <div class="fi-popup-body">
                    <p class="poppins v2-font-15 v2-m-font-14 medium dark-text-2 text-center v2-mw-50 mx-auto v2-m-mw-100">
                        Need some help to finance -no problem we got you! Your initial earnings will go towards the payment, 
                        and there is a charge of 10% to do so
                    </p>
                    
                    <div class="v2-mt-1 fi-popup-tbl">
                        <div class="fi-popup-tbl-header">
                            <div class="fi-popup-tbl-header-col">
                                
                            </div>
                            <div class="fi-popup-tbl-header-col">
                                Max Term
                            </div>
                            <div class="fi-popup-tbl-header-col">
                                Min Down
                            </div>
                            <div class="fi-popup-tbl-header-col">
                                Finance Charge 10%
                            </div>
                        </div>
                        
                                        
                        <div class="fi-popup-tbl-body">
                                                    <div class="fi-popup-tbl-body-row">
                                    <div class="fi-popup-tbl-body-col fi-popup-tbl-body-col-1">
                                        <label><input type="checkbox" name="finance[]" value="Small $1500" /> Small $1500</label>
                                    </div>
                                    <div class="fi-popup-tbl-body-col">
                                        <span class="fi-popup-text">Max Term</span> 30 days							</div>
                                    <div class="fi-popup-tbl-body-col">
                                        <span class="fi-popup-text">Min Down</span> $300							</div>
                                    <div class="fi-popup-tbl-body-col">
                                        <span class="fi-popup-text">Charge 10%</span> $150							</div>
                                </div>
                                                    <div class="fi-popup-tbl-body-row">
                                    <div class="fi-popup-tbl-body-col fi-popup-tbl-body-col-1">
                                        <label><input type="checkbox" name="finance[]" value="Small $3000" /> Small $3000</label>
                                    </div>
                                    <div class="fi-popup-tbl-body-col">
                                        <span class="fi-popup-text">Max Term</span> 60 days							</div>
                                    <div class="fi-popup-tbl-body-col">
                                        <span class="fi-popup-text">Min Down</span> $500							</div>
                                    <div class="fi-popup-tbl-body-col">
                                        <span class="fi-popup-text">Charge 10%</span> $300							</div>
                                </div>
                                                    <div class="fi-popup-tbl-body-row">
                                    <div class="fi-popup-tbl-body-col fi-popup-tbl-body-col-1">
                                        <label><input type="checkbox" name="finance[]" value="Large $5000" /> Large $5000</label>
                                    </div>
                                    <div class="fi-popup-tbl-body-col">
                                        <span class="fi-popup-text">Max Term</span> 90 days							</div>
                                    <div class="fi-popup-tbl-body-col">
                                        <span class="fi-popup-text">Min Down</span> $750							</div>
                                    <div class="fi-popup-tbl-body-col">
                                        <span class="fi-popup-text">Charge 10%</span> $500							</div>
                                </div>
                                            </div>
                    </div>
                    
                    <div class="v2-mt-2 text-center btn-wrap">
                        <a href="#" class="site-btn site-btn-2 site-btn-pink fi-btn">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default FinancePopup;