import React, { useState, useEffect } from 'react';

const Timer = ({ initialHours = 0, initialMinutes = 30, initialSeconds = 1 }) => {
  const [time, setTime] = useState({
    hours: initialHours,
    minutes: initialMinutes,
    seconds: initialSeconds
  });

  useEffect(() => {
    const countdown = setInterval(() => {
      const { hours, minutes, seconds } = time;

      if (hours === 0 && minutes === 0 && seconds === 0) {
        clearInterval(countdown);
      } else if (seconds > 0) {
        setTime({ ...time, seconds: seconds - 1 });
      } else if (minutes > 0) {
        setTime({ hours, minutes: minutes - 1, seconds: 59 });
      } else if (hours > 0) {
        setTime({ hours: hours - 1, minutes: 59, seconds: 59 });
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [time]);

  const formatTime = (timeUnit) => String(timeUnit).padStart(2, '0');

  return (
    <div>
      {formatTime(time.hours)}:{formatTime(time.minutes)}:{formatTime(time.seconds)}
    </div>
  );
};

export default Timer;