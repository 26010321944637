import { Link, useLocation } from 'react-router-dom';

function FooterMenu({ title, menuItems }) {

  return (
    <div className='footer-menu-wrap'>
        <div className='footer-menu-title'>{title}</div>
        { menuItems && (
            <ul className='footer-menu'>
                { menuItems.map((item, index) => (
                    <li>
                        <a 
                            href={item.url} 
                            target={item.newTab ? "_blank" : "_self"} 
                            rel="noopener noreferrer"
                        >
                            {item.title}
                        </a>
                    </li>
                ))
                }
            </ul>
        ) }
    </div>
  );
}

export default FooterMenu;