import React, { Component } from 'react';

// Components
import Header from '../components/header/Header2';
import Footer from '../components/footer/Footer2';


import Section1 from '../components/home/Section1';
import Section3 from '../components/home/Section3';
import Section4 from '../components/home/Section4';
import Section5 from '../components/home/Section5';
import Section6 from '../components/home/Section6';
import Section7 from '../components/home/Section7';

// CSS

class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
        {/* Header */}
        <Header />
        
        {/* SECTION 1 */}
        <Section1 />

        {/* SECTION 3 */}
        <Section3 />

        {/* Section 4 */}
        <Section4 />

        {/* Section 5 */}
        <Section5 />

        {/* Section 6 */}
        <Section6 />

        {/* Section 7 */}
        <Section7 />

        {/* Footer */}
        <Footer />
        </>
    );
  }
}

export default Home;