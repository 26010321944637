import React, { Component } from 'react';
import { Row, Col, Input, Label, Button, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { deviceDetect, EXPLORER_URL, OVATO_URL, WALLET_URL } from '../../constants/defaultValues';
import { Service } from '../../providers/Services';
import { ToastContainer, toast } from 'react-toastify';
// import DaoComingSoonModal from './DaoComingSoonModal';

// Popups
// import RelaunchingPopup from '../components/popup/RelaunchingPopup';
// import FloatingIcons from '../components/floating-icons/FloatingIcons';

// CSS
import '../../assets/css/NewBottomNav3.css'

// Images 
import ImgBitovation from '../../assets/img/bottom-nav-3/logo-bitovation.png'
import ImgGovato from '../../assets/img/bottom-nav-3/logo-govato.png'
import ImgOtext from '../../assets/img/bottom-nav-3/logo-otext.png'
import ImgPolygon from '../../assets/img/bottom-nav-3/logo-polygon.png'
import ImgOpenSea from '../../assets/img/bottom-nav-3/logo-opensea.png'
import ImgOvato from '../../assets/img/bottom-nav-3/img-ovato.png'

import ImgAppStore from '../../assets/img/img-app-store-footer.png'
import ImgGooglePlay from '../../assets/img/img-google-play-footer.png'

const toaststyle = {
  position: 'top-right',
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};

class NewBottomNav3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isInvalid: '',
      showDaoModal: false,
      modal: false,
      copyright: ''
    };
  }
  toggleDaoModal = (e, value) => {
    e.preventDefault();
        this.setState({ showDaoModal: value })
    }
  handleInput = (e) => {
    this.setState({
      isInvalid: false,
      [e.target.name]: e.target.value,
    });
  };
  componentDidMount() { 
    // Service.getCopyright()
    // .then(res=>{
    //   this.setState({copyright:res.msg})
    // })
   }
  handleSubmit = () => {
    if (this.state.email === '') {
      toast.error("Email can't be empty !", toaststyle);
      this.setState({ isInvalid: true });
    } else {
      this.setState({ isInvalid: false });
      const payload = {
        email: this.state.email,
        os: deviceDetect.os,
        browser: deviceDetect.browsername,
        device: deviceDetect.devicetype,
        plateform: deviceDetect.plateform,
        type: 'home',
      };
      Service.subscribeForm(payload)
        .then((res) => {
          this.setState({ email: '' });
          toast.success('Successfully subscribed!', toaststyle);
        })
        .catch((error) => {
          if (error.status === 400) {
            toast.error(
              'Email is invalid, please enter a valid email!',
              toaststyle
            );
          } else {
            toast.error(
              error && error.response
                ? error.response.data.message
                : 'Some error occurred!',
              toaststyle
            );
          }
        });
    }
  };
  render() {
    const { t } = this.props;

    const toggleModal = (e) => {
      e.preventDefault();
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }

    return (
      <>
      <div className='bottom-nav-3'>

        <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />

        <div className='bottom_main new-bottom-main'>
          <Container className='footer-container-1'>
            <Row className='bottom_main_row'>
              {/* COMPANY COLUMN */}
              <Col>
                <span className='bottom_main_head'>
                  General
                </span>
                <ul className='list-unstyled mt-3 bottom_list'>
                  <li>
                    <a href={OVATO_URL + '/about'} target='_blank'>About</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/media'} target='_blank'>Media</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/contact'} target='_blank'>Contact Us</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/wallet'} target='_blank' rel="noopener noreferrer">Get Started</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/yellowpaper'} target='_blank'>Technology</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/support'} target='_blank'>Support</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/exchange'} target='_blank'>Exchange</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/merchant'} target='_blank'>Become a Merchant</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/wallet'} target='_blank'>Get a Wallet</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/financial-overview-and-roadmap-2024'} target='_blank'>Financial Overview</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/about'} target='_blank'>FAQs</a>
                  </li>
                </ul>
              </Col>
              {/* LEARN MORE COLUMN */}
              <Col>
                <span className='bottom_main_head'>
                  Learn More
                </span>
                <ul className='list-unstyled mt-3 bottom_list'>
                  <li>
                    <a href={OVATO_URL + '/exchange'} target='_blank'>Press</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/wallet'} target='_blank' rel="noopener noreferrer">
                      Application
                    </a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/whitepaper'} target='_blank'>White Paper</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/wallet'} target='_blank' rel="noopener noreferrer">Explorer</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/brand-assets'} target='_blank'>Brand Assets</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/stacking'} target='_blank'>Stack Coins</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/media'} target='_blank'>Media</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/support'} target='_blank'>Support</a>
                  </li>
                  <li>
                    <a href={OVATO_URL + '/bug-bounty'} target='_blank'>Report a bug (get paid)</a>
                  </li>
                </ul>
              </Col>
              {/* Connect With Us Column */}
              <Col>
                <span className='bottom_main_head'>
                    Community
                </span>
                <ul className='list-unstyled mt-3 bottom_list'>
                  <li>
                    {' '}
                    <a
                      href='https://www.facebook.com/OvatoCoin'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      rel='noopener noreferrer'
                      href='https://www.youtube.com/watch?v=QvFtAtVnSik'
                      target='_blank'
                    >
                      Youtube
                    </a>
                  </li>
                  <li>
                    <a
                      rel='noopener noreferrer'
                      href='https://www.linkedin.com/company/ovato/mycompany/?viewAsMember=true'
                      target='_blank'
                    >
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                      rel='noopener noreferrer'
                      href='https://twitter.com/OvatoCoin'
                      target='_blank'
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      rel='noopener noreferrer'
                      href='https://www.instagram.com/ovatocoin/'
                      target='_blank'
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      rel='noopener noreferrer'
                      href='#'
                      target='_blank'
                    >
                     Github
                    </a>
                  </li>
                  <li>
                    <a
                      href='/meet-ups'
                    >
                      Meet Ups
                    </a>
                  </li>
                </ul>
              </Col>
              {/* Connect With Us Column */}
              {/* Industry Solutions & Govato */}
              <Col>
                <span className='bottom_main_head'>
                    Industry Solutions
                </span>

                <ul className='list-unstyled mt-3 bottom_list'>
                  <li><a href={OVATO_URL + '/dispensary'} target='_blank'>Cannabis</a></li>
                  <li><a href={OVATO_URL + '/escorts'} target='_blank'>Entertainers</a></li>
                  <li><a href={OVATO_URL + '/unbanked'} target='_blank'>Un Banked</a></li>
                  <li><a href='#'>New Immigrants</a></li>
                  <li><a href='#'>Overview</a></li>
                </ul>

                <span className='v2-mt-3 v2-mb-0 bottom_main_head'>
                    Govato
                </span>

                <ul className='list-unstyled mt-3 bottom_list'>
                  <li><a href={OVATO_URL + `leaderboard`} target='_blank'>Leaderboard</a></li>
                  <li><a href='#'>About Premium</a></li>
                  <li><a href={OVATO_URL + `nfts`} target='_blank'>NFTs</a></li>
                </ul>
              </Col>
              {/* Connect With Us Column */}
              <Col>
                <span className='bottom_main_head'>
                  Partners
                </span>
                <ul className='list-unstyled mt-3 bottom_list footer-v3-partners'>
                  <li><a href='https://bitovation.com/' target='_blank' rel="noopener noreferrer"><img width={87} src={ImgBitovation} /></a></li>
                  <li><a href='https://govato.io/' target='_blank' rel="noopener noreferrer"><img width={75} src={ImgGovato} /></a></li>
                  <li><a href='http://otext.io/' target='_blank' rel="noopener noreferrer"><img width={56} src={ImgOtext} /></a></li>
                  <li><a href='https://mumbai.polygonscan.com/' target='_blank' rel="noopener noreferrer"><img width={86} src={ImgPolygon} /></a></li>
                  <li><a href='https://opensea.io/' target='_blank' rel="noopener noreferrer"><img width={87} src={ImgOpenSea} /></a></li>
                </ul>
              </Col>
              {/* Join our newsletter col */}
              <Col className='text-center new-footer-join-newsletter-col'>
                <a href='/'>
                  <img
                    alt='Ovato RGB'
                    src={ImgOvato}
                    className='footer-v3-logo' 
                    width={200}
                  />
                </a>
                <Label className='v2-mt-2' style={{ fontWeight: 'bold', fontSize: 19 }}>
                  Join our Newsletter
                </Label>
                <center className='v2-mt-0-5'>
                  <Input
                    type='email'
                    name='email'
                    value={this.state.email}
                    placeholder={t('Email Address')}
                    className='bottom_input footer-email-input'
                    style={{
                      borderRadius: 60,
                      height: 50,
                      border: '1px solid white',
                      color: 'white',
                      paddingLeft: '20px',
                    }}
                    required
                    invalid={this.state.isInvalid}
                    onChange={this.handleInput}
                  />
                </center>
                <Button
                  className='bottom_subscribe'
                  style={{ borderRadius: 60 }}
                  onClick={this.handleSubmit}
                >
                  Subscribe Now
                </Button>
                <div className='v2-mt-1 bottom-nav-download-wrap'>
                  <a
                    //href='https://play.google.com/store/apps/details?id=com.ovato.wallet'
                    href='#'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={(e) => toggleModal(e)}
                  >
                    <img
                      alt='App Store'
                      className='bottom_storeicon'
                      src={ImgAppStore}
                      width={50} 
                    />
                  </a>
                  &nbsp;
                  <a
                    // href='https://apps.apple.com/us/app/ovato-wallet/id1520107226'
                    href='#'
                    target='_blank'
                    rel='noopener noreferrer' 
                    onClick={(e) => toggleModal(e)}
                  >
                    <img
                      alt='Google Play'
                      className='bottom_storeicon'
                      src={ImgGooglePlay} 
                      width={50} 
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
          <div className='text-center mt-5 footer-container-2'>
            <p className='bottom_info_text desktop'>
              <a href='/privacy-policy'>Privacy Policy</a>
              &emsp;|&emsp;
              <a href={OVATO_URL + '/terms-and-condition'} target='_blank'>
                Terms and Conditions
              </a>
              &emsp;|&emsp; Copyright © {new Date().getFullYear()} Ovato &emsp;|&emsp;
              <span>All Rights Reserved</span>
            </p>
            <p className='bottom_info_text mobile'>
                <span><a href={OVATO_URL + '/privacy-policy'} target='_blank'>Privacy Policy</a></span>
              <span><a href={OVATO_URL + '/terms-and-condition'} target='_blank'>
                Terms and Conditions
              </a></span>
              <span>Copyright © {new Date().getFullYear()} Ovato</span>
              <span>All Rights Reserved</span>
            </p>
          </div>
        </div>
      </div>
      {/* {this.state.showDaoModal && <DaoComingSoonModal show={this.state.showDaoModal}  toggleDaoModal={this.toggleDaoModal} />} */}

      {/* Floating Social Icons */}
      {/* <FloatingIcons /> */}
      
      {/* Modals */}
      {/* <RelaunchingPopup toggle={toggleModal} modal={this.state.modal} /> */}
      </>
    );
  }
}
export default withTranslation()(NewBottomNav3);