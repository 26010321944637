import React, { Component } from 'react';

import { User } from '../../providers/User';

// CSS
import '../../assets/css/TopMenuSteps.css'

class TopMenuSteps extends Component {
  constructor(props) {
    super(props);

    this.state = {
        
    };
    
  }

  render() {

    const { t, handleNavigation, handleLogout, isMobile } = this.props;

    const mobileClass = isMobile ? 'mobile-site-menu top-menu-steps-mobile' : 'site-menu site-menu-2 top-menu-steps'

    return (
        <>
            <ul className={mobileClass}>
                <li>
                    <a href="/how-it-works" onClick={(e) => 1(e, "how-it-works") }>
                        <span className='tms-text'>Step 01</span>
                        How it Works
                    </a>
                </li>
                <li>
                    <a href="/compensation-plan" onClick={(e) => handleNavigation(e, "compensation-plan") }>
                        <span className='tms-text'>Step 02</span>
                        Compensation Plan
                    </a>
                </li>
                <li>
                    <a href="/tools" onClick={(e) => handleNavigation(e, "tools") }>
                        <span className='tms-text'>Step 03</span>
                        Tools
                    </a>
                </li>
                <li>
                    <a href="/nfts" onClick={(e) => handleNavigation(e, "nfts") }>
                        <span className='tms-text'>Step 04</span>
                        NFTs
                    </a>
                </li>
                <li>
                    <a href="/get-started" onClick={(e) => handleNavigation(e, "get-started") }>
                        <span className='tms-text'>Step 05</span>
                        Get Started
                    </a>
                </li>
                
                <li><a className='tms-menu-end' href="/rules" onClick={(e) => handleNavigation(e, "rules") }>Rules</a></li>
                
                { User.hasToken() && <li><a className='tms-menu-end' href="#" onClick={() => handleLogout() }>Logout</a></li> }
            </ul>
        </>
    );
  }
}

export default TopMenuSteps;