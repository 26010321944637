import React, { Component } from 'react';

import { Container, Row, Col } from 'react-bootstrap';

// Components
import FooterMenu from './FooterMenu';
import FooterForm from './FooterForm';
import FooterCopyright from './FooterCopyright';

// CSS
import '../../assets/css/Footer.css'

// Start 
class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    // Menu 1 Lists
    const menu1 = [
        { title: 'About', url: 'https://www.ovato.com/aboutus', newTab: true },
        { title: 'Media', url: 'https://www.ovato.com/media', newTab: true },
        { title: 'Contact Us', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Get Started', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Technology', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Support', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Exchanges', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Wallets', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Become a Merchant', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Get a Wallet', url: 'https://www.ovato.com/contact-us', newTab: true }
    ]

    // Menu 2 Lists
    const menu2 = [
        { title: 'Press', url: 'https://www.ovato.com/aboutus', newTab: true },
        { title: 'Application', url: 'https://www.ovato.com/media', newTab: true },
        { title: 'White Paper', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Explorer', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Brand Assets', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Faucet', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Stack Coins', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Media', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Support', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Bug Bounty', url: 'https://www.ovato.com/en/bug-bounty', newTab: true }
    ]

    // Menu 3 Lists
    const menu3 = [
        { title: 'Facebook', url: 'https://www.ovato.com/aboutus', newTab: true },
        { title: 'Youtube', url: 'https://www.ovato.com/media', newTab: true },
        { title: 'Linkedin', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Twitter', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Instagram', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Discord', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Github', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Reddit', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Meet Ups', url: 'https://www.ovato.com/contact-us', newTab: true },
    ]

    // Menu 4 Lists
    const menu4 = [
        { title: 'Owards.com', url: 'https://www.ovato.com/aboutus', newTab: true },
        { title: 'otext.net', url: 'https://www.ovato.com/media', newTab: true },
        { title: 'Ovato.nft', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Ovato.dao', url: 'https://www.ovato.com/contact-us', newTab: true },
        { title: 'Bitovation.com', url: 'https://www.ovato.com/contact-us', newTab: true },
    ]

    return (
        <footer className='footer'>
            <div className='footer-upper'>
                <Container>
                    <Row>
                        <Col>
                            <FooterMenu title={'Company'} menuItems={menu1} />
                        </Col>

                        <Col>
                            <FooterMenu title={'Learn More'} menuItems={menu2} />
                        </Col>

                        <Col>
                            <FooterMenu title={'Connect with Us'} menuItems={menu3} />
                        </Col>

                        <Col>
                            <FooterMenu title={'Affiliated Partners'} menuItems={menu4} />
                        </Col>

                        <Col>
                            <FooterForm />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='footer-lower'>
                <Container>
                    <Row>
                        <Col>
                            <FooterCopyright />
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    );
  }
}

export default Footer;