import React, { Component } from 'react';

// Hooks
import ModalVideoManager from '../components/modal/ModalVideoManager';

// Components
import Header from '../components/header/Header2';
import Footer from '../components/footer/Footer2';

// Images
import ImgVideoTools from '../assets/img/tools/img-video-tools-2.jpg'
import ImgEbook from '../assets/img/tools/img-education-ebook.png'
import ImgEbookMobile from '../assets/img/tools/img-ebook-mobile.png'
import ImgConsumerProduct from '../assets/img/tools/img-consumer-product-01.png'
import ImgQrCode from '../assets/img/tools/img-qr-code.png'
import ImgBusiness from '../assets/img/tools/img-business-and-merchant-promotional-products.png'
import ImgPersonalize from '../assets/img/tools/img-personalize-media-content.png'
import ImgPersonalizeMobile from '../assets/img/tools/img-personalized-mobile.png'
import ImgRealTime from '../assets/img/tools/img-real-time-data.png'
import ImgNewsletter from '../assets/img/tools/img-newsletter-and-telegram.png'
import ToolsLoggedOut from '../components/tools/ToolsLoggedOut';

class Tools extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    
    return (
        <>
            <Header />

            <ToolsLoggedOut /> 

            <Footer />
        </>
    );
  }
}

export default Tools;