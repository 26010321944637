import React, { Component } from 'react';

class Section3 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
        <section class="new-home-3">
          <div class="container">
            <div class="row new-home-3-row new-home-3-row-new">
              <div class="new-home-3-col col-sm-3">
                <div class="new-home-3-col-inner">
                  <p class="new-home-3-col-text-1">
                    3+
                  </p>
                  <p class="new-home-3-col-text-2">
                    Years in the <br /> Industry
                  </p>
                </div>
              </div>
              
              <div class="new-home-3-col col-sm-3">
                <div class="new-home-3-col-inner">
                  <p class="new-home-3-col-text-1">
                    2.7M+
                  </p>
                  <p class="new-home-3-col-text-2">
                    Blocks Settled
                  </p>
                </div>
              </div>
              
              <div class="new-home-3-col col-sm-3">
                <div class="new-home-3-col-inner">
                  <p class="new-home-3-col-text-1">
                    30%
                  </p>
                  <p class="new-home-3-col-text-2">
                    Average Annual Increase
                  </p>
                </div>
              </div>
              
              <div class="new-home-3-col col-sm-3">
                <div class="new-home-3-col-inner">
                  <p class="new-home-3-col-text-1">
                    4.8
                  </p>
                  <p class="new-home-3-col-text-2">
                    App Store Ratings
                  </p>
                </div>
              </div>
              
            </div>
          </div>
        </section>
        </>
    );
  }
}

export default Section3;