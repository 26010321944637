import React, { Component } from 'react';

// Images
import ImgRocketLeft from '../../assets/img/home/img-rocket-left.png'
import ImgRocketMobile from '../../assets/img/home/img-rocket-mobile.png'

import ImgMembership from '../../assets/img/home/img-membership-home.png'
import ImgNFT from '../../assets/img/home/img-nft-3-ways.png'
import ImgBuilding from '../../assets/img/home/img-building-wealth-new.png'

class Section7 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
        <section class="home-3 new-home-33 new-last-section">
            <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <h2 class="text-center home-3-heading home-3-heading-new dark-text-2">
                            3 Great Ways to Earn
                        </h2>
                    </div>
                </div>
                {/* <!-- end class row --> */}
                
                <div class="row">
                    <div class="v2-m-mt-0 col-sm home-3-col">
                        <h3 class="home-3-subheading">
                            Transactionally
                        </h3>
                        <div class="img-wrap home-3-img-wrap">
                            <img src={ImgMembership} />
                        </div>
                        <h4 class="home-3-subheading">
                            Memberships and Wallets
                        </h4>
                        <p class="home-3-text">
                            Earn great residuals on all members. Build a residual income each and every month. The easiest way to onboard new users is with a free wallet and earn anywhere from $2 to $20 per new wallet
                        </p>
                    </div>

                    {/* <!-- end class col-sm --> */}
                    <div class="v2-tp-mt-2 v2-m-mt-1 col-sm home-3-col">
                        <h3 class="home-3-subheading">
                            Business in a box to sell
                        </h3>
                        <div class="img-wrap home-3-img-wrap">
                            <img src={ImgNFT} />
                        </div>
                        <h4 class="home-3-subheading">
                            Exit Plan
                        </h4>
                        <p class="home-3-text">
                            Earn 25% on your members NFT purchases ranging from $1000-$10,000. Also earn money in your NFT yourself and sell for compounding earnings
                        </p>
                    </div>

                    {/* <!-- end class col-sm --> */}
                    <div class="v2-tp-mt-2 v2-m-mt-1 col-sm home-3-col">
                        <h3 class="home-3-subheading">
                            Accumulating value
                        </h3>
                        <div class="img-wrap home-3-img-wrap">
                            <img src={ImgBuilding} />
                        </div>
                        <h4 class="home-3-subheading">
                            Building Wealth
                        </h4>
                        <ul class="ul-checklist">
                            <li>Earn OVO on your merchants and members</li>	
                            <li>Residual OVO earnings on your NFT sales</li>
                            <li>Earn OVO on networks stacking</li>
                        </ul>
                    </div>

                    {/* <!-- end class col-sm --> */}
                </div>
                {/* <!-- end class row --> */}
                
                <div class="row">
                    <div class="btn-wrap home-3-btn-wrap">
                            <a href="/how-it-works" class="site-btn site-btn-2 site-btn-subtext  signup-v3-btn">
                            Begin Presentation
                    </a>
                        </div>
                </div>
                {/* <!-- end class row --> */}
            </div>
            {/* <!-- end class container --> */}
        </section>
        </>
    );
  }
}

export default Section7;