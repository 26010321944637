import React, { Component } from 'react';

// Components
import Header from '../components/header/Header2';
import Footer from '../components/footer/Footer2';
import FinancePopup from '../components/popup/FinancePopup';

// Images
import imgGovato from '../assets/img/get-started-2/logo-govato.png'
import imgSmall from '../assets/img/get-started/img-small.png'
import imgMedium from '../assets/img/get-started/img-medium.png'
import imgLarge from '../assets/img/get-started/img-large.png'

// CSS
import '../assets/css/GetStarted2.css'

class GetStarted2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false
    }

    this.handleClosePopup = this.handleClosePopup.bind(this);
  }

  handleShowPopup(e) {
    e.preventDefault()
    this.setState({
      showPopup: true
    })
  }

  handleClosePopup(e) {
    e.preventDefault()
    this.setState({
      showPopup: false
    })
  }

  render() {
    return (
        <>
            <Header />

            {/* Section 1 */}
            <section className='v2-get-started-1'>
                <div className='container'>

                    {/* <div className='row'>
                      <div className='text-center img-wrap'>
                        <a href='/'><img src={imgGovato} width={100} /></a>
                      </div>
                    </div> */}

                    <div className='v2-mt-1 row v2-gs-row'>

                        <div className='col col-sm-4 text-center v2-gs-col v2-gs-col-complete'>
                            <div className='v2-gs-status-main'>
                                <div className='v2-gs-status-wrap'>
                                    <span className='v2-gs-status-num'>1</span>
                                    <span className='v2-gs-status-text v2-gs-status-text-completed'>completed</span>
                                </div>
                            </div>

                            <div className='v2-gs-status-subtext'>Connect Your Wallet</div>
                        </div>

                        <div className='col col-sm-4 text-center v2-gs-col'>
                            <div className='v2-gs-status-main'>
                                <div className='v2-gs-status-wrap'>
                                    <span className='v2-gs-status-num'>2</span>
                                    <span className='v2-gs-status-text v2-gs-status-text-active'>to do</span>
                                </div>
                            </div>
                            
                            <div className='v2-gs-status-subtext'>Preview Art and Select Size</div>
                        </div>

                        <div className='col col-sm-4 text-center v2-gs-col'>
                            <div className='v2-gs-status-main'>
                                <div className='v2-gs-status-wrap'>
                                    <span className='v2-gs-status-num'>3</span>
                                    <span className='v2-gs-status-text'>TO DO</span>
                                </div>
                            </div>

                            <div className='v2-gs-status-subtext'>Purchase Premium NFT Wallet</div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Section 2 */}
            <section className='v2-get-started-2'>
                <div className='container v2-gs-2-container'>
                    <div className='row'>
                        <div className='col'>
                            <h2 class="poppins text-center n-font-48 bold dark-text-2 v2-tp-mt--2 v2-m-mt--6"><strong class="double-underline double-underline-heading">Start Earning Now</strong></h2>
                        </div>
                    </div>

                    <div className='v2-mt-4 v2-tp-mt-2 v2-m-mt-0 row v2-gs-2-row-2'>

                        <div className='col col-sm-4 v2-tp-order-2 v2-tp-mt-8 v2-tp-col-6 v2-m-col-12'>
                            <div className='v2-gs-box v2-gs-box-starting'>
                                <div className='v2-gs-box-header'>
                                    Series B
                                </div>

                                <div className='v2-gs-box-body'>
                                    <div>NFT's # 550 - 4000</div>
                                    <div>100% OVO Bonus</div>
                                </div>
                            </div>
                        </div>

                        <div className='col col-sm-4 v2-tp-order-3'>
                            <div className='v2-gs-box v2-gs-box-available v2-gs-box-running v2-gs-blue-box v2-gs-big-box'>
                                <div className='v2-gs-box-header'>
                                    Series A
                                </div>

                                <div className='v2-gs-box-body'>
                                    <div>NFT's #1-550</div>
                                    <div>200% OVO Bonus</div>
                                </div>
                            </div>
                        </div>

                        <div className='col col-sm-4 v2-tp-order-1 v2-tp-mt-8 v2-m-mt-2 v2-tp-col-6 v2-m-col-12'>
                            <div className='v2-gs-box'>
                                <div className='v2-gs-box-header'>
                                    Series C
                                </div>

                                <div className='v2-gs-box-body'>
                                    <div>NFT's #4001 - 10,000</div>
                                    <div>100% Coverage</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            
            <section class="new-last-section v2-get-started-3">
              <div class="container">	
                
                <div class="row gs-sml-row">
                  
                  <div class="col">
                    <div class="gs-sml-main">
                      <div class="gs-sml-header">
                        <div class="gs-sml-header-col">
                          <div class="gs-sml-header-title">
                            Small
                          </div>
                          <div class="gs-sml-header-price">
                            $1500
                          </div>
                          <div className='gs-sml-header-ovo'>Contains: 5,000 OVO</div>
                        </div>
                        <div class="gs-sml-header-col">
                          <div class="gs-sml-header-img">
                            <img src={imgSmall} />
                          </div>
                        </div>
                      </div>
                      {/* <!-- end class gs-sml-header --> */}

                        <div class="gs-sml-body-main">
                            <div class="gs-sml-body">
                                <div class="gs-sml-level">
                                <div class="gs-sml-level-title">
                                    Level 1
                                </div>

                                <ul class="gs-sml-ul">
                                    <li>Wallets: $2 - $20</li>
                                    <li>Membership: 25%</li>
                                    <li>NFTs: 25%</li>
                                    <li>Merchants: $100</li>
                                    <li>Royalties: 15%</li>
                                </ul>
                                </div>
                            </div>
                            {/* <!-- end class gs-sml-body --> */}

                            <div class="gs-sml-footer">
                                <div class="gs-sml-btn-wrap">
                                <a href="https://ovato.com/en/nfts" target="_blank" rel="noopener noreferrer" class="site-btn site-btn-2 site-btn-pink site-btn-subtext">
                                    Select NFT
                                </a>
                                </div>

                                <div class="gs-sml-btn-wrap">
                                <a href="#" class="site-cta open-finance-it" onClick={(e) => this.handleShowPopup(e)}>
                                    Finance It &gt;
                                </a>
                                </div>
                            </div>
                        </div>
                      
                      {/* <!-- end class gs-sml-footer --> */}
                    </div>
                    {/* <!-- end class gs-sml-main --> */}
                  </div>
                  {/* <!-- end class col --> */}
                  
                  <div class="col">
                    <div class="gs-sml-main">
                      <div class="gs-sml-header">
                        <div class="gs-sml-header-col">
                          <div class="gs-sml-header-title">
                            Medium
                          </div>
                          <div class="gs-sml-header-price">
                            $3000
                          </div>
                          <div className='gs-sml-header-ovo'>Contains: 12,000 OVO</div>
                        </div>
                        <div class="gs-sml-header-col">
                          <div class="gs-sml-header-img">
                            <img src={imgMedium} />
                          </div>
                        </div>
                      </div>
                      {/* <!-- end class gs-sml-header --> */}

                      <div class="gs-sml-body-main">
                            <div class="gs-sml-body">
                                <div class="gs-sml-level">
                                <div class="gs-sml-level-title">
                                    Level 1
                                </div>

                                <ul class="gs-sml-ul">
                                    <li>Wallets: $2 - $20</li>
                                    <li>Membership: 25%</li>
                                    <li>NFTs: 25%</li>
                                    <li>Merchants: $100</li>
                                    <li>Royalties: 15%</li>
                                </ul>
                                </div>
                                {/* <!-- end class gs-sml-level --> */}

                                <div class="gs-sml-level">
                                <div class="gs-sml-level-title">
                                    Level 2
                                </div>

                                <ul class="gs-sml-ul">
                                    <li>Wallets: $3</li>
                                    <li>Membership: 20%</li>
                                    <li>NFTs: 20%</li>
                                    <li>Merchants: $25</li>
                                    <li>Royalties: 30%</li>
                                </ul>
                                </div>
                                {/* <!-- end class gs-sml-level --> */}
                            </div>
                            {/* <!-- end class gs-sml-body --> */}

                            <div class="gs-sml-footer">
                                <div class="gs-sml-btn-wrap">
                                <a href="https://ovato.com/en/nfts" target="_blank" rel="noopener noreferrer" class="site-btn site-btn-2 site-btn-pink site-btn-subtext">
                                    Select NFT
                                </a>
                                </div>

                                <div class="gs-sml-btn-wrap">
                                <a href="#" class="site-cta open-finance-it" onClick={(e) => this.handleShowPopup(e)}>
                                    Finance It &gt;
                                </a>
                                </div>
                            </div>
                      </div>

                      {/* <!-- end class gs-sml-footer --> */}
                    </div>
                    {/* <!-- end class gs-sml-main --> */}
                  </div>
                  {/* <!-- end class col --> */}
                  
                  <div class="col">
                    <div class="gs-sml-main">
                      <div class="gs-sml-header">
                        <div class="gs-sml-header-col">
                          <div class="gs-sml-header-title">
                            Large
                          </div>
                          <div class="gs-sml-header-price">
                            $5000
                          </div>
                          <div className='gs-sml-header-ovo'>Contains: 25,000 OVO</div>
                        </div>
                        <div class="gs-sml-header-col">
                          <div class="gs-sml-header-img">
                            <img src={imgLarge} />
                          </div>
                        </div>
                      </div>
                      {/* <!-- end class gs-sml-header --> */}

                      <div class="gs-sml-body-main">
                            <div class="gs-sml-body">
                                <div class="gs-sml-level">
                                <div class="gs-sml-level-title">
                                    Level 1
                                </div>

                                <ul class="gs-sml-ul">
                                    <li>Wallets: $2 - $20</li>
                                    <li>Membership: 25%</li>
                                    <li>NFTs: 25%</li>
                                    <li>Merchants: $100</li>
                                    <li>Royalties: 15%</li>
                                </ul>
                                </div>
                                {/* <!-- end class gs-sml-level --> */}

                                <div class="gs-sml-level">
                                <div class="gs-sml-level-title">
                                    Level 2
                                </div>

                                <ul class="gs-sml-ul">
                                    <li>Wallets: $4</li>
                                    <li>Membership: 25%</li>
                                    <li>NFTs: 25%</li>
                                    <li>Merchants: 30%</li>
                                    <li>Royalties: 35%</li>
                                </ul>
                                </div>
                                {/* <!-- end class gs-sml-level --> */}
                            </div>
                            {/* <!-- end class gs-sml-body --> */}

                            <div class="gs-sml-footer">
                                <div class="gs-sml-btn-wrap">
                                <a href="https://ovato.com/en/nfts" target="_blank" rel="noopener noreferrer" class="site-btn site-btn-2 site-btn-pink site-btn-subtext">
                                    Select NFT
                                </a>
                                </div>

                                <div class="gs-sml-btn-wrap">
                                <a href="#" class="site-cta open-finance-it" onClick={(e) => this.handleShowPopup(e)}>
                                    Finance It &gt;
                                </a>
                                </div>
                            </div>
                            {/* <!-- end class gs-sml-footer --> */}
                      </div>

                    </div>
                    {/* <!-- end class gs-sml-main --> */}
                  </div>
                  {/* <!-- end class col --> */}
                </div>
                
              </div>
            </section>

            {/* Finance Popup */}
            <FinancePopup show={this.state.showPopup} handleClose={this.handleClosePopup} />

            {/* Footer */}
            <Footer />
        </>
    );
  }
}

export default GetStarted2;