import React, { Component } from 'react';

// CSS
import '../assets/css/Discover.css'
import '../assets/css/Discover2.css'
import '../assets/css/Discover3.css'

// Components
import Header from '../components/header/Header2';
import Section1 from '../components/discover3/Section1';
import Footer from '../components/footer/Footer';

class Discover2 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className='discover-main v2-discover-main v3-discover-main'>
            {/* Header */}
            <Header />
            
            {/* SECTION 1 */}
            <Section1 />

            {/* FOoter */}
            <Footer />
        </div>
    );
  }
}

export default Discover2;